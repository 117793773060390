import { z } from 'zod';
import { Prisma } from '@prisma/client';
import Decimal from 'decimal.js';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

export const transformJsonNull = (v?: NullableJsonInput) => {
  if (!v || v === 'DbNull') return Prisma.DbNull;
  if (v === 'JsonNull') return Prisma.JsonNull;
  return v;
};

export const JsonValueSchema: z.ZodType<Prisma.JsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.literal(null),
    z.record(z.lazy(() => JsonValueSchema.optional())),
    z.array(z.lazy(() => JsonValueSchema)),
  ])
);

export type JsonValueType = z.infer<typeof JsonValueSchema>;

export const NullableJsonValue = z
  .union([JsonValueSchema, z.literal('DbNull'), z.literal('JsonNull')])
  .nullable()
  .transform((v) => transformJsonNull(v));

export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

export const InputJsonValueSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.object({ toJSON: z.function(z.tuple([]), z.any()) }),
    z.record(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
    z.array(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
  ])
);

export type InputJsonValueType = z.infer<typeof InputJsonValueSchema>;

// DECIMAL
//------------------------------------------------------

export const DecimalJsLikeSchema: z.ZodType<Prisma.DecimalJsLike> = z.object({
  d: z.array(z.number()),
  e: z.number(),
  s: z.number(),
  toFixed: z.function(z.tuple([]), z.string()),
})

export const DECIMAL_STRING_REGEX = /^(?:-?Infinity|NaN|-?(?:0[bB][01]+(?:\.[01]+)?(?:[pP][-+]?\d+)?|0[oO][0-7]+(?:\.[0-7]+)?(?:[pP][-+]?\d+)?|0[xX][\da-fA-F]+(?:\.[\da-fA-F]+)?(?:[pP][-+]?\d+)?|(?:\d+|\d*\.\d+)(?:[eE][-+]?\d+)?))$/;

export const isValidDecimalInput =
  (v?: null | string | number | Prisma.DecimalJsLike): v is string | number | Prisma.DecimalJsLike => {
    if (v === undefined || v === null) return false;
    return (
      (typeof v === 'object' && 'd' in v && 'e' in v && 's' in v && 'toFixed' in v) ||
      (typeof v === 'string' && DECIMAL_STRING_REGEX.test(v)) ||
      typeof v === 'number'
    )
  };

/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const UserScalarFieldEnumSchema = z.enum(['id','createdAt','username','name','email','avatarUrl','roles']);

export const PatientScalarFieldEnumSchema = z.enum(['id','createdAt','updatedById','updateSource','externalId','firstName','lastName','dob','gender','zipcode','city','address','state','phone','email','extra','prescriber','treatment','clientId','mergedAt']);

export const PatientBenefitInvestigationScalarFieldEnumSchema = z.enum(['id','createdAt','updatedById','updateSource','inputPayload','updatePayload','savingsId','savingsGroup','prescriberFirstName','prescriberLastName','prescriberState','prescriberNpi','enrollmentType','transactionType','indication','dosageName','programName','totalBenefitCurrentPeriod','remainingBenefitCurrentPeriod','isAtRiskForMaximizer','isAtRiskForAccumulator','atRiskIndicatorCodes','riskIndicator1','riskIndicator2','riskIndicator3','enrollmentSource','autoCompleteStatus','autoCompletedBy','status','enrollmentStatus','startAfter','dueDate','agentInvestigationCompletedAt','completedAt','omniCheckTransactionId','claimAnalysisTransactionId','pmgTransactionId','bpsV1TransactionId','autocompleteExecutionReferences','heldForItInvestigationUntil','heldForItInvestigationReason','isResultSubmissionHeld','isResultSubmissionOnHoldByClient','deductibleType','deductibleAmount','deductibleRemaining','loadAmount','fundType','oopType','oopMaxAmount','oopRemainingAmount','isDrugNEHB','drugCoverageStatus','hasPharmacyBenefitCap','benefitCapAmount','coPayment','reasonForCoPaymentEmpty','needsToMeetDeductible','appliesTowardsAccumulators','autoCompleteStatusDescription','statusDescription','standardProgramUpdateReason','maximizerProgramId','maximizerProgramStartDate','maximizerOptInDate','patientId','productId','investigatorId','qcInspectorId','patientProductId','parentBenefitInvestigationId','patientCardTransactionId']);

export const PatientProductScalarFieldEnumSchema = z.enum(['id','createdAt','patientId','productId','externalId','savingsId']);

export const PatientNoteScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','updatedById','updateSource','note','category','group','patientBenefitInvestigationId','authorId','templateId']);

export const PatientCardTransactionScalarFieldEnumSchema = z.enum(['id','createdAt','clientNumber','clientName','programNumber','programName','firstName','lastName','cardholderId','accountNumber','trueTransactionAmount','transactionDescription','transactionStatus','balance','mccCode','mccDesc','merchantName','merchantAddress','cardNumber','currency','cardEntryMethod','transactionTime','originalPatientBIId']);

export const PatientClaimScalarFieldEnumSchema = z.enum(['id','createdAt','clientClaimId','claimType','savingsId','groupNum','ndc','nabp','quantity','daySupply','initialOutOfPocket','programBenefit','patientResponsibility','dateOfFill','dateOfService','pharmacyName','physicianNpi','submissionMethod','claimStatus','drugDescription','clientOriginalClaimId','bin','brand','rxNumber','totalBenefitCurrentPeriod','remainingBenefitCurrentPeriod','otherCoverageCode','raw','wasUsedInClaimAnalysis','patientId']);

export const PatientInsuranceScalarFieldEnumSchema = z.enum(['id','createdAt','updatedById','updateSource','isActive','source','memberID','planName','payerName','group','planTypeId','bin','pcn','planStartDate','planEndDate','pbmId','patientId']);

export const ClientScalarFieldEnumSchema = z.enum(['id','createdAt','type','companyName','sftpUsername','sftpHomeDir','benefitResultWebhookUrl','benefitResultSubscribedFields','pgpPublicKey','isResultSubmissionEnabled']);

export const ProductScalarFieldEnumSchema = z.enum(['id','createdAt','ndc','productName','clientId']);

export const InsuranceToBIMappingScalarFieldEnumSchema = z.enum(['id','createdAt','planOrderIndex','insuranceId','patientBenefitInvestigationId']);

export const ChangeLogScalarFieldEnumSchema = z.enum(['id','createdAt','source','modelName','modelField','rowId','oldValue','newValue','userId']);

export const BpsFilterScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','type','name','isPinned','ownerId','segmentation','createdOnFrom','createdOnTo','dobFrom','dobTo','dueDateFrom','dueDateTo','bins','pcns','groups','riskIndicators','patientClaimDateRange','initialOopFrom','initialOopTo','enrollmentStatus','autoCompleteStatusDescription','status','includeNewWithNullSegmentation','includeAllInvestigators','onlyDelayed']);

export const BpsFilterAssignmentScalarFieldEnumSchema = z.enum(['id','createdAt','filterId','assignToAllInvestigators']);

export const PatientBenefitManagerScalarFieldEnumSchema = z.enum(['id','createdAt','name','phones']);

export const PatientInsurancePlanTypeScalarFieldEnumSchema = z.enum(['id','createdAt','name']);

export const ControlReportScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','clientId','fileKey','fileName','processingDate','status']);

export const ControlReportRecordScalarFieldEnumSchema = z.enum(['id','createdAt','controlReportId','programName','brandName','memberID','patientExternalId','copayStartAt','status','mismatchedFields','controlFileData','databaseData','patientId','productId','patientBenefitInvestigationId']);

export const BiCompletionLogScalarFieldEnumSchema = z.enum(['id','createdAt','patientBenefitInvestigationId','clientPayload','clientResponse','error','note','isAtRiskForMaximizer','isAtRiskForAccumulator','primaryInsurance','maximizerProgramId','investigatorId','qcInspectorId']);

export const MaximizerProgramScalarFieldEnumSchema = z.enum(['id','createdAt','name','bins','phoneNumber','isActive']);

export const PatientNoteTemplateScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','name','category','group','note']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const NullableJsonNullValueInputSchema = z.enum(['DbNull','JsonNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.DbNull : value);

export const JsonNullValueInputSchema = z.enum(['JsonNull',]).transform((value) => (value === 'JsonNull' ? Prisma.JsonNull : value));

export const QueryModeSchema = z.enum(['default','insensitive']);

export const NullsOrderSchema = z.enum(['first','last']);

export const UserOrderByRelevanceFieldEnumSchema = z.enum(['id','username','name','email','avatarUrl']);

export const JsonNullValueFilterSchema = z.enum(['DbNull','JsonNull','AnyNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.JsonNull : value === 'AnyNull' ? Prisma.AnyNull : value);

export const PatientOrderByRelevanceFieldEnumSchema = z.enum(['id','updatedById','externalId','firstName','lastName','zipcode','city','address','state','phone','email','clientId']);

export const PatientBenefitInvestigationOrderByRelevanceFieldEnumSchema = z.enum(['id','updatedById','savingsId','savingsGroup','prescriberFirstName','prescriberLastName','prescriberState','prescriberNpi','indication','dosageName','programName','totalBenefitCurrentPeriod','remainingBenefitCurrentPeriod','atRiskIndicatorCodes','riskIndicator3','omniCheckTransactionId','claimAnalysisTransactionId','pmgTransactionId','bpsV1TransactionId','autocompleteExecutionReferences','heldForItInvestigationReason','deductibleType','oopType','drugCoverageStatus','reasonForCoPaymentEmpty','statusDescription','standardProgramUpdateReason','maximizerProgramId','patientId','productId','investigatorId','qcInspectorId','patientProductId','parentBenefitInvestigationId','patientCardTransactionId']);

export const PatientProductOrderByRelevanceFieldEnumSchema = z.enum(['id','patientId','productId','externalId','savingsId']);

export const PatientNoteOrderByRelevanceFieldEnumSchema = z.enum(['id','updatedById','note','patientBenefitInvestigationId','authorId','templateId']);

export const PatientCardTransactionOrderByRelevanceFieldEnumSchema = z.enum(['id','clientNumber','clientName','programNumber','programName','firstName','lastName','cardholderId','accountNumber','transactionDescription','transactionStatus','mccCode','mccDesc','merchantName','merchantAddress','cardNumber','currency','cardEntryMethod','originalPatientBIId']);

export const PatientClaimOrderByRelevanceFieldEnumSchema = z.enum(['id','clientClaimId','claimType','savingsId','groupNum','ndc','nabp','pharmacyName','physicianNpi','submissionMethod','drugDescription','clientOriginalClaimId','bin','brand','rxNumber','totalBenefitCurrentPeriod','remainingBenefitCurrentPeriod','otherCoverageCode','patientId']);

export const PatientInsuranceOrderByRelevanceFieldEnumSchema = z.enum(['id','updatedById','memberID','planName','payerName','group','planTypeId','bin','pcn','pbmId','patientId']);

export const ClientOrderByRelevanceFieldEnumSchema = z.enum(['id','companyName','sftpUsername','sftpHomeDir','benefitResultWebhookUrl','pgpPublicKey']);

export const ProductOrderByRelevanceFieldEnumSchema = z.enum(['id','ndc','productName','clientId']);

export const InsuranceToBIMappingOrderByRelevanceFieldEnumSchema = z.enum(['id','insuranceId','patientBenefitInvestigationId']);

export const ChangeLogOrderByRelevanceFieldEnumSchema = z.enum(['id','modelField','rowId','oldValue','newValue','userId']);

export const BpsFilterOrderByRelevanceFieldEnumSchema = z.enum(['id','name','ownerId','segmentation','bins','pcns','groups']);

export const BpsFilterAssignmentOrderByRelevanceFieldEnumSchema = z.enum(['id','filterId']);

export const PatientBenefitManagerOrderByRelevanceFieldEnumSchema = z.enum(['id','name','phones']);

export const PatientInsurancePlanTypeOrderByRelevanceFieldEnumSchema = z.enum(['id','name']);

export const ControlReportOrderByRelevanceFieldEnumSchema = z.enum(['id','clientId','fileKey','fileName']);

export const ControlReportRecordOrderByRelevanceFieldEnumSchema = z.enum(['id','controlReportId','programName','brandName','memberID','patientExternalId','patientId','productId','patientBenefitInvestigationId']);

export const BiCompletionLogOrderByRelevanceFieldEnumSchema = z.enum(['id','patientBenefitInvestigationId','error','note','maximizerProgramId','investigatorId','qcInspectorId']);

export const MaximizerProgramOrderByRelevanceFieldEnumSchema = z.enum(['id','name','bins','phoneNumber']);

export const PatientNoteTemplateOrderByRelevanceFieldEnumSchema = z.enum(['id','name','note']);

export const BenefitInvestigationStatusSchema = z.enum(['NEW','PENDING','READY_FOR_QC','AUTO_COMPLETED','ESCALATED','AUTO_COMPLETED_FOR_QC','HELD_FOR_IT_INVESTIGATION','QC_PENDING','REINVESTIGATE','COMPLETED','NOT_REQUIRED']);

export type BenefitInvestigationStatusType = `${z.infer<typeof BenefitInvestigationStatusSchema>}`

export const BiAutoCompleteStatusSchema = z.enum(['PATIENT_NOT_FOUND','NON_COMMERCIAL','NO_RISK_BIN']);

export type BiAutoCompleteStatusType = `${z.infer<typeof BiAutoCompleteStatusSchema>}`

export const ControlReportStatusSchema = z.enum(['IN_PROGRESS','COMPLETED','FAILED']);

export type ControlReportStatusType = `${z.infer<typeof ControlReportStatusSchema>}`

export const ControlReportRecordStatusSchema = z.enum(['MATCHED','MISMATCHED','NOT_FOUND','NOT_TRANSMITTED']);

export type ControlReportRecordStatusType = `${z.infer<typeof ControlReportRecordStatusSchema>}`

export const EnrollmentSourceSchema = z.enum(['SFTP','API','MANUAL','IMPORT','CLAIM_ANALYSIS','CARD_TRANSACTION']);

export type EnrollmentSourceType = `${z.infer<typeof EnrollmentSourceSchema>}`

export const ChangeLogSourceSchema = z.enum(['API','USER','SFTP','SCRIPT','INTERNAL_TOOL']);

export type ChangeLogSourceType = `${z.infer<typeof ChangeLogSourceSchema>}`

export const BenefitResultClientSubscribedFieldsSchema = z.enum(['transactionId','patientId','productId','memberNumber']);

export type BenefitResultClientSubscribedFieldsType = `${z.infer<typeof BenefitResultClientSubscribedFieldsSchema>}`

export const GenderSchema = z.enum(['MALE','FEMALE','OTHER']);

export type GenderType = `${z.infer<typeof GenderSchema>}`

export const PatientInsuranceSourceSchema = z.enum(['CLIENT','ENROLLMENT_API','SFTP','MANUAL','OMNI_CHECK','PMG']);

export type PatientInsuranceSourceType = `${z.infer<typeof PatientInsuranceSourceSchema>}`

export const UserRoleSchema = z.enum(['ADMIN','INVESTIGATOR','QC']);

export type UserRoleType = `${z.infer<typeof UserRoleSchema>}`

export const NoteCategorySchema = z.enum(['BI','ESCALATE','REINVESTIGATE','INBOUND_ESCALATION_RESULT','CALL']);

export type NoteCategoryType = `${z.infer<typeof NoteCategorySchema>}`

export const NoteGroupSchema = z.enum(['BPS','INBOUND']);

export type NoteGroupType = `${z.infer<typeof NoteGroupSchema>}`

export const ClientTypeSchema = z.enum(['GNE_CRX','JNJ_IBM','JNJ_CMTX']);

export type ClientTypeType = `${z.infer<typeof ClientTypeSchema>}`

export const EnrollmentTypeSchema = z.enum(['NEW_ENROLLMENT','RENEWAL','RETURNING_PATIENT']);

export type EnrollmentTypeType = `${z.infer<typeof EnrollmentTypeSchema>}`

export const TransactionTypeSchema = z.enum(['NEW_ENROLLMENT','UPDATE_ENROLLMENT','OFFCYCLE','REPLENISHMENT']);

export type TransactionTypeType = `${z.infer<typeof TransactionTypeSchema>}`

export const ChangeLogModelSchema = z.enum(['PATIENT','PATIENT_BENEFIT_INVESTIGATION','PATIENT_INSURANCE','PATIENT_NOTE']);

export type ChangeLogModelType = `${z.infer<typeof ChangeLogModelSchema>}`

export const PatientClaimStatusSchema = z.enum(['PAID','REVERSED','DENIED']);

export type PatientClaimStatusType = `${z.infer<typeof PatientClaimStatusSchema>}`

export const EnrollmentStatusSchema = z.enum(['ACTIVE','INACTIVE','PENDING']);

export type EnrollmentStatusType = `${z.infer<typeof EnrollmentStatusSchema>}`

export const RiskSegmentationSchema = z.enum(['Y','N','U','N_A']);

export type RiskSegmentationType = `${z.infer<typeof RiskSegmentationSchema>}`

export const ExtendedBooleanSchema = z.enum(['Y','N','U','N_A']);

export type ExtendedBooleanType = `${z.infer<typeof ExtendedBooleanSchema>}`

export const BiAutoCompletedBySchema = z.enum(['BDM','CLAIM_ANALYSIS']);

export type BiAutoCompletedByType = `${z.infer<typeof BiAutoCompletedBySchema>}`

export const BiAutoCompleteStatusDescriptionSchema = z.enum(['E1_NULL','NEEDS_INSURANCE','NEEDS_INSURANCE_AND_MAXIMIZER_PROGRAM','NEEDS_MAXIMIZER_PROGRAM','UNKNOWN_PRODUCT_NAME','NEEDS_PAYMENT_INFORMATION']);

export type BiAutoCompleteStatusDescriptionType = `${z.infer<typeof BiAutoCompleteStatusDescriptionSchema>}`

export const PatientClaimFilterDateRangeSchema = z.enum(['THIS_YEAR','PREVIOUS_YEAR']);

export type PatientClaimFilterDateRangeType = `${z.infer<typeof PatientClaimFilterDateRangeSchema>}`

export const RiskIndicatorSchema = z.enum(['PREVAIL_KEYWORD_MATCH','PREVAIL_BPG_MATCH','CONFIRMED_BY_REP','CONFIRMED_BY_PLAN_LEVEL_INFORMATION','PAYER_PORTAL_MESSAGE','REPEATING_30_OR_50_COINSURANCE','REPEATING_COPAY_12_OR_13','PATIENT_RESPONSIBILITY_IS_ALWAYS_THE_REMAINING_ACCUMULATORS','PATIENT_RESPONSIBILITY_IS_FULL_COST_OR_FULL_ACCUMULATORS','ONLY_5_PATIENT_RESPONSIBILITY_IS_APPLYING_TO_ACCUMULATORS','CONFIRMED_BY_ADJUSTMENTS_TO_ACCUMULATORS','ACCUMULATORS_ARE_GOING_DOWN_OR_MET','PLAN_EXCLUSION_DISCOUNTED_RATE_APPLIED','NEED_MORE_CLAIMS','TEST_CLAIM_NOT_AVAILABLE_OR_PA_REQUIRED','GRACE_FILL','OUT_OF_NETWORK_PENALTY','DISCOUNT_CARD_WAS_USED','INDEMNITY_PLAN_OR_REIMBURSED_THROUGH_MAJOR_MEDICAL','CONFIRMED_BY_HISTORICAL_CLAIMS','COMMERCIAL_N','NO_RISK_BIN','HIGH_COPAY_DUE_TO_BENEFIT_CAP','PATIENT_NO_LONGER_ON_THERAPY','E1_NOT_FOUND','HIGH_REMAINING_ACCUMULATORS','COPAYMENT_WILL_NOT_APPLY_TOWARDS_ACCUMULATORS','PATIENT_RESPONSIBILITY_LESS_THAN_900']);

export type RiskIndicatorType = `${z.infer<typeof RiskIndicatorSchema>}`

export const FundTypeSchema = z.enum(['CREDIT','DEBIT']);

export type FundTypeType = `${z.infer<typeof FundTypeSchema>}`

export const FilterTypeSchema = z.enum(['BI','CLAIM']);

export type FilterTypeType = `${z.infer<typeof FilterTypeSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  roles: UserRoleSchema.array(),
  id: z.string(),
  createdAt: z.coerce.date(),
  username: z.string(),
  name: z.string().nullish(),
  email: z.string().nullish(),
  avatarUrl: z.string().nullish(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// PATIENT SCHEMA
/////////////////////////////////////////

export const PatientSchema = z.object({
  updateSource: ChangeLogSourceSchema.nullish(),
  gender: GenderSchema.nullish(),
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedById: z.string().nullish(),
  externalId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  dob: z.coerce.date(),
  zipcode: z.string(),
  city: z.string().nullish(),
  address: z.string().nullish(),
  state: z.string().nullish(),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  extra: JsonValueSchema.nullable(),
  prescriber: JsonValueSchema.nullable(),
  treatment: JsonValueSchema.nullable(),
  clientId: z.string(),
  mergedAt: z.coerce.date().nullish(),
})

export type Patient = z.infer<typeof PatientSchema>

/////////////////////////////////////////
// PATIENT BENEFIT INVESTIGATION SCHEMA
/////////////////////////////////////////

export const PatientBenefitInvestigationSchema = z.object({
  updateSource: ChangeLogSourceSchema.nullish(),
  enrollmentType: EnrollmentTypeSchema.nullish(),
  transactionType: TransactionTypeSchema.nullish(),
  isAtRiskForMaximizer: RiskSegmentationSchema.nullish(),
  isAtRiskForAccumulator: RiskSegmentationSchema.nullish(),
  riskIndicator1: RiskIndicatorSchema.nullish(),
  riskIndicator2: RiskIndicatorSchema.nullish(),
  enrollmentSource: EnrollmentSourceSchema,
  autoCompleteStatus: BiAutoCompleteStatusSchema.nullish(),
  autoCompletedBy: BiAutoCompletedBySchema.nullish(),
  status: BenefitInvestigationStatusSchema,
  enrollmentStatus: EnrollmentStatusSchema,
  fundType: FundTypeSchema.nullish(),
  isDrugNEHB: ExtendedBooleanSchema.nullish(),
  autoCompleteStatusDescription: BiAutoCompleteStatusDescriptionSchema.nullish(),
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedById: z.string().nullish(),
  inputPayload: JsonValueSchema.nullable(),
  updatePayload: JsonValueSchema.nullable(),
  savingsId: z.string(),
  savingsGroup: z.string().nullish(),
  prescriberFirstName: z.string().nullish(),
  prescriberLastName: z.string().nullish(),
  prescriberState: z.string().nullish(),
  prescriberNpi: z.string().nullish(),
  indication: z.string().nullish(),
  dosageName: z.string().nullish(),
  programName: z.string().nullish(),
  totalBenefitCurrentPeriod: z.string().nullish(),
  remainingBenefitCurrentPeriod: z.string().nullish(),
  atRiskIndicatorCodes: z.string().array(),
  riskIndicator3: z.string().nullish(),
  startAfter: z.coerce.date().nullish(),
  dueDate: z.coerce.date().nullish(),
  agentInvestigationCompletedAt: z.coerce.date().nullish(),
  completedAt: z.coerce.date().nullish(),
  omniCheckTransactionId: z.string().nullish(),
  claimAnalysisTransactionId: z.string().nullish(),
  pmgTransactionId: z.string().nullish(),
  bpsV1TransactionId: z.string().nullish(),
  autocompleteExecutionReferences: z.string().array(),
  heldForItInvestigationUntil: z.coerce.date().nullish(),
  heldForItInvestigationReason: z.string().nullish(),
  isResultSubmissionHeld: z.boolean().nullish(),
  isResultSubmissionOnHoldByClient: z.boolean().nullish(),
  deductibleType: z.string().nullish(),
  deductibleAmount: z.number().int().nullish(),
  deductibleRemaining: z.number().int().nullish(),
  loadAmount: z.number().int().nullish(),
  oopType: z.string().nullish(),
  oopMaxAmount: z.number().int().nullish(),
  oopRemainingAmount: z.number().int().nullish(),
  drugCoverageStatus: z.string().nullish(),
  hasPharmacyBenefitCap: z.boolean().nullish(),
  benefitCapAmount: z.number().int().nullish(),
  coPayment: z.number().int().nullish(),
  reasonForCoPaymentEmpty: z.string().nullish(),
  needsToMeetDeductible: z.boolean().nullish(),
  appliesTowardsAccumulators: z.boolean().nullish(),
  statusDescription: z.string().nullish(),
  standardProgramUpdateReason: z.string().nullish(),
  maximizerProgramId: z.string().nullish(),
  maximizerProgramStartDate: z.coerce.date().nullish(),
  maximizerOptInDate: z.coerce.date().nullish(),
  patientId: z.string(),
  productId: z.string(),
  investigatorId: z.string().nullish(),
  qcInspectorId: z.string().nullish(),
  patientProductId: z.string().nullish(),
  parentBenefitInvestigationId: z.string().nullish(),
  patientCardTransactionId: z.string().nullish(),
})

export type PatientBenefitInvestigation = z.infer<typeof PatientBenefitInvestigationSchema>

/////////////////////////////////////////
// PATIENT PRODUCT SCHEMA
/////////////////////////////////////////

export const PatientProductSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  patientId: z.string(),
  productId: z.string(),
  externalId: z.string().nullish(),
  savingsId: z.string().nullish(),
})

export type PatientProduct = z.infer<typeof PatientProductSchema>

/////////////////////////////////////////
// PATIENT NOTE SCHEMA
/////////////////////////////////////////

export const PatientNoteSchema = z.object({
  updateSource: ChangeLogSourceSchema.nullish(),
  category: NoteCategorySchema,
  group: NoteGroupSchema,
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date().nullish(),
  updatedById: z.string().nullish(),
  note: z.string(),
  patientBenefitInvestigationId: z.string(),
  authorId: z.string().nullish(),
  templateId: z.string().nullish(),
})

export type PatientNote = z.infer<typeof PatientNoteSchema>

/////////////////////////////////////////
// PATIENT CARD TRANSACTION SCHEMA
/////////////////////////////////////////

export const PatientCardTransactionSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  clientNumber: z.string().nullish(),
  clientName: z.string().nullish(),
  programNumber: z.string().nullish(),
  programName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  cardholderId: z.string(),
  accountNumber: z.string(),
  trueTransactionAmount: z.instanceof(Prisma.Decimal, { message: "Field 'trueTransactionAmount' must be a Decimal. Location: ['Models', 'PatientCardTransaction']"}),
  transactionDescription: z.string().nullish(),
  transactionStatus: z.string().nullish(),
  balance: z.instanceof(Prisma.Decimal, { message: "Field 'balance' must be a Decimal. Location: ['Models', 'PatientCardTransaction']"}),
  mccCode: z.string().nullish(),
  mccDesc: z.string().nullish(),
  merchantName: z.string().nullish(),
  merchantAddress: z.string().nullish(),
  cardNumber: z.string(),
  currency: z.string().nullish(),
  cardEntryMethod: z.string().nullish(),
  transactionTime: z.coerce.date(),
  originalPatientBIId: z.string().nullish(),
})

export type PatientCardTransaction = z.infer<typeof PatientCardTransactionSchema>

/////////////////////////////////////////
// PATIENT CLAIM SCHEMA
/////////////////////////////////////////

export const PatientClaimSchema = z.object({
  claimStatus: PatientClaimStatusSchema.nullish(),
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  clientClaimId: z.string(),
  claimType: z.string().nullish(),
  savingsId: z.string().nullish(),
  groupNum: z.string().nullish(),
  ndc: z.string().nullish(),
  nabp: z.string().nullish(),
  quantity: z.instanceof(Prisma.Decimal, { message: "Field 'quantity' must be a Decimal. Location: ['Models', 'PatientClaim']"}).nullish(),
  daySupply: z.number().int().nullish(),
  initialOutOfPocket: z.instanceof(Prisma.Decimal, { message: "Field 'initialOutOfPocket' must be a Decimal. Location: ['Models', 'PatientClaim']"}).nullish(),
  programBenefit: z.instanceof(Prisma.Decimal, { message: "Field 'programBenefit' must be a Decimal. Location: ['Models', 'PatientClaim']"}).nullish(),
  patientResponsibility: z.instanceof(Prisma.Decimal, { message: "Field 'patientResponsibility' must be a Decimal. Location: ['Models', 'PatientClaim']"}).nullish(),
  dateOfFill: z.coerce.date().nullish(),
  dateOfService: z.coerce.date().nullish(),
  pharmacyName: z.string().nullish(),
  physicianNpi: z.string().nullish(),
  submissionMethod: z.string().nullish(),
  drugDescription: z.string().nullish(),
  clientOriginalClaimId: z.string().nullish(),
  bin: z.string().nullish(),
  brand: z.string().nullish(),
  rxNumber: z.string().nullish(),
  totalBenefitCurrentPeriod: z.string().nullish(),
  remainingBenefitCurrentPeriod: z.string().nullish(),
  otherCoverageCode: z.string().nullish(),
  raw: JsonValueSchema.nullable(),
  wasUsedInClaimAnalysis: z.boolean().nullish(),
  patientId: z.string(),
})

export type PatientClaim = z.infer<typeof PatientClaimSchema>

/////////////////////////////////////////
// PATIENT INSURANCE SCHEMA
/////////////////////////////////////////

export const PatientInsuranceSchema = z.object({
  updateSource: ChangeLogSourceSchema.nullish(),
  source: PatientInsuranceSourceSchema,
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedById: z.string().nullish(),
  isActive: z.boolean().nullish(),
  memberID: z.string().nullish(),
  planName: z.string().nullish(),
  payerName: z.string().nullish(),
  group: z.string().nullish(),
  planTypeId: z.string().nullish(),
  bin: z.string().nullish(),
  pcn: z.string().nullish(),
  planStartDate: z.coerce.date().nullish(),
  planEndDate: z.coerce.date().nullish(),
  pbmId: z.string().nullish(),
  patientId: z.string(),
})

export type PatientInsurance = z.infer<typeof PatientInsuranceSchema>

/////////////////////////////////////////
// CLIENT SCHEMA
/////////////////////////////////////////

export const ClientSchema = z.object({
  type: ClientTypeSchema.nullish(),
  benefitResultSubscribedFields: BenefitResultClientSubscribedFieldsSchema.array(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  companyName: z.string().nullish(),
  sftpUsername: z.string().nullish(),
  sftpHomeDir: z.string().nullish(),
  benefitResultWebhookUrl: z.string().nullish(),
  pgpPublicKey: z.string().nullish(),
  isResultSubmissionEnabled: z.boolean().nullish(),
})

export type Client = z.infer<typeof ClientSchema>

/////////////////////////////////////////
// PRODUCT SCHEMA
/////////////////////////////////////////

export const ProductSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  ndc: z.string().nullish(),
  productName: z.string(),
  clientId: z.string(),
})

export type Product = z.infer<typeof ProductSchema>

/////////////////////////////////////////
// INSURANCE TO BI MAPPING SCHEMA
/////////////////////////////////////////

export const InsuranceToBIMappingSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  planOrderIndex: z.number().int().nullish(),
  insuranceId: z.string(),
  patientBenefitInvestigationId: z.string(),
})

export type InsuranceToBIMapping = z.infer<typeof InsuranceToBIMappingSchema>

/////////////////////////////////////////
// CHANGE LOG SCHEMA
/////////////////////////////////////////

export const ChangeLogSchema = z.object({
  source: ChangeLogSourceSchema.nullish(),
  modelName: ChangeLogModelSchema,
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  modelField: z.string(),
  rowId: z.string(),
  oldValue: z.string().nullish(),
  newValue: z.string().nullish(),
  userId: z.string().nullish(),
})

export type ChangeLog = z.infer<typeof ChangeLogSchema>

/////////////////////////////////////////
// BPS FILTER SCHEMA
/////////////////////////////////////////

export const BpsFilterSchema = z.object({
  type: FilterTypeSchema,
  riskIndicators: RiskIndicatorSchema.array(),
  patientClaimDateRange: PatientClaimFilterDateRangeSchema.array(),
  enrollmentStatus: EnrollmentStatusSchema.nullish(),
  autoCompleteStatusDescription: BiAutoCompleteStatusDescriptionSchema.array(),
  status: BenefitInvestigationStatusSchema.array(),
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  isPinned: z.boolean(),
  ownerId: z.string(),
  segmentation: z.string().array(),
  createdOnFrom: z.coerce.date().nullish(),
  createdOnTo: z.coerce.date().nullish(),
  dobFrom: z.coerce.date().nullish(),
  dobTo: z.coerce.date().nullish(),
  dueDateFrom: z.coerce.date().nullish(),
  dueDateTo: z.coerce.date().nullish(),
  bins: z.string().array(),
  pcns: z.string().array(),
  groups: z.string().array(),
  initialOopFrom: z.number().int().nullish(),
  initialOopTo: z.number().int().nullish(),
  includeNewWithNullSegmentation: z.boolean().nullish(),
  includeAllInvestigators: z.boolean().nullish(),
  onlyDelayed: z.boolean().nullish(),
})

export type BpsFilter = z.infer<typeof BpsFilterSchema>

/////////////////////////////////////////
// BPS FILTER ASSIGNMENT SCHEMA
/////////////////////////////////////////

export const BpsFilterAssignmentSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  filterId: z.string(),
  assignToAllInvestigators: z.boolean().nullish(),
})

export type BpsFilterAssignment = z.infer<typeof BpsFilterAssignmentSchema>

/////////////////////////////////////////
// PATIENT BENEFIT MANAGER SCHEMA
/////////////////////////////////////////

export const PatientBenefitManagerSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  name: z.string(),
  phones: z.string().array(),
})

export type PatientBenefitManager = z.infer<typeof PatientBenefitManagerSchema>

/////////////////////////////////////////
// PATIENT INSURANCE PLAN TYPE SCHEMA
/////////////////////////////////////////

export const PatientInsurancePlanTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  name: z.string(),
})

export type PatientInsurancePlanType = z.infer<typeof PatientInsurancePlanTypeSchema>

/////////////////////////////////////////
// CONTROL REPORT SCHEMA
/////////////////////////////////////////

export const ControlReportSchema = z.object({
  status: ControlReportStatusSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  clientId: z.string(),
  fileKey: z.string(),
  fileName: z.string(),
  processingDate: z.coerce.date(),
})

export type ControlReport = z.infer<typeof ControlReportSchema>

/////////////////////////////////////////
// CONTROL REPORT RECORD SCHEMA
/////////////////////////////////////////

export const ControlReportRecordSchema = z.object({
  status: ControlReportRecordStatusSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  controlReportId: z.string(),
  programName: z.string(),
  brandName: z.string(),
  memberID: z.string(),
  patientExternalId: z.string(),
  copayStartAt: z.coerce.date().nullish(),
  mismatchedFields: JsonValueSchema.nullable(),
  controlFileData: JsonValueSchema,
  databaseData: JsonValueSchema.nullable(),
  patientId: z.string().nullish(),
  productId: z.string().nullish(),
  patientBenefitInvestigationId: z.string().nullish(),
})

export type ControlReportRecord = z.infer<typeof ControlReportRecordSchema>

/////////////////////////////////////////
// BI COMPLETION LOG SCHEMA
/////////////////////////////////////////

export const BiCompletionLogSchema = z.object({
  isAtRiskForMaximizer: RiskSegmentationSchema.nullish(),
  isAtRiskForAccumulator: RiskSegmentationSchema.nullish(),
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  patientBenefitInvestigationId: z.string(),
  clientPayload: JsonValueSchema.nullable(),
  clientResponse: JsonValueSchema.nullable(),
  error: z.string().nullish(),
  note: z.string().nullish(),
  primaryInsurance: JsonValueSchema.nullable(),
  maximizerProgramId: z.string().nullish(),
  investigatorId: z.string().nullish(),
  qcInspectorId: z.string().nullish(),
})

export type BiCompletionLog = z.infer<typeof BiCompletionLogSchema>

/////////////////////////////////////////
// MAXIMIZER PROGRAM SCHEMA
/////////////////////////////////////////

export const MaximizerProgramSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  name: z.string(),
  bins: z.string().array(),
  phoneNumber: z.string().nullish(),
  isActive: z.boolean(),
})

export type MaximizerProgram = z.infer<typeof MaximizerProgramSchema>

/////////////////////////////////////////
// PATIENT NOTE TEMPLATE SCHEMA
/////////////////////////////////////////

export const PatientNoteTemplateSchema = z.object({
  category: NoteCategorySchema,
  group: NoteGroupSchema,
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  note: z.string(),
})

export type PatientNoteTemplate = z.infer<typeof PatientNoteTemplateSchema>
